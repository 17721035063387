<template>
  <div class="articleMain">
    <div v-wechat-title="$route.meta.title"></div>
    <div class="container">
      <div>
        <el-skeleton
          class="skeleton"
          :rows="13"
          animated
          v-if="articleLoading == true"
        />
      </div>
      <div
        style="display:flex"
        v-if="articleLoading == false"
      >
        <div>
          <div
            class="coverPhoto"
            :style="{background:'url(' + photoUrl(articleInfo.cover_photo) + ') no-repeat center center/cover'}"
          >
            <div class="headerText">
              <div class="test222">
                <div class="headerTitle">
                  {{articleInfo.title}}
                </div>
                <div>
                  <span class="category"><i class="fa fa-inbox"></i>&nbsp;&nbsp;{{articleInfo.category_name}}</span>
                  <span
                    v-for="tagItem in articleInfo.tag_name"
                    :key="tagItem.id"
                    class="tag"
                  >#{{tagItem.tag_name}}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="articleContent">
            <div class="padding">
              <div class="background">
                <div class="padding">
                  <div class="titleTop">
                    <div class="titile_like">
                      <h1 class="title">{{articleInfo.title}}</h1>
                      <div
                        v-if="articleIsLike"
                        class="like_true"
                      >
                        <el-popover
                          placement="right"
                          :width="160"
                        >
                          <template #reference>
                            <i
                              class="fa fa-heart"
                              aria-hidden="true"
                              @click="like()"
                            ></i>
                          </template>
                          <template #default>
                            取消点赞。(＞﹏＜)
                          </template>
                        </el-popover>
                      </div>
                      <div
                        v-else
                        class="like_false"
                      >
                        <el-popover
                          placement="right"
                          :width="160"
                        >
                          <template #reference>
                            <i
                              class="fa fa-heart-o"
                              aria-hidden="true"
                              @click="like()"
                            ></i>
                          </template>
                          <template #default>
                            喜欢这篇文章的话，请点赞一下。 ^_~
                          </template>
                        </el-popover>
                      </div>

                    </div>
                    <span class="createTime"><i
                        class="fa fa-calendar"></i>&nbsp;&nbsp;{{articleInfo.create_time}}</span>
                  </div>
                  <hr class="contentHr" />
                  <div
                    v-html="articleInfo.content"
                    class="content"
                    id="content"
                  >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 文章目录 -->
        <div class="catalogue_list">
          <!-- 标题 -->
          <div class="catalogue_title">目&nbsp;录</div>
          <!-- <el-divider /> -->
          <el-progress
            :text-inside="false"
            :stroke-width="3"
            :percentage="progressPercentage"
            :show-text="false"
            color="#f7cb5d"
          />
          <!-- 目录信息 -->
          <!-- <el-scrollbar class="scrollbar"> -->
          <div class="catalogue_info">
            <div
              v-for="item in catalogueInfo"
              :key="item"
              class="catalogue_item"
              :id="'catalogue_' + item.id"
              :style="{'font-size':item.level == 2 ? '1.1rem' : '','margin-top':item.level == 2 ? '4px' : ''}"
            >
              <a :href="'#'+item.id">
                <div :style="{'margin-left': (20 * (item.level - 2))+'px'}">{{item.title}}</div>
              </a>
            </div>
          </div>
          <!-- </el-scrollbar> -->

        </div>
      </div>
      <!-- 底部评论 -->
      <div class="comment">
        <!-- 评论输入框 -->
        <div class="comment_input">
          <div class="comment_title">
            <i
              class="fa fa-comments"
              aria-hidden="true"
            ></i>
            评论
          </div>
          <el-input
            v-model="article_comment.comment_content"
            :rows="7"
            type="textarea"
            placeholder="说点什么吧...  (若内容或图片失效，请留言反馈。部分素材来自网络，若不小心影响到您的利益，请联系我们删除。)"
          />
          <div class="commentButton">
            <el-button
              v-if="$store.state.isLogin"
              type="primary"
              @click="insertArticleComment()"
              class="comment_button"
              :loading="articleCommentButtonLoading"
            >提交评论</el-button>
            <el-popover
              placement="left"
              v-else
            >
              <template #reference>
                <el-button
                  type="primary"
                  class="comment_button"
                >提交评论</el-button>
              </template>
              <template #default>
                需要登录才能发表评论哦。^_~
              </template>
            </el-popover>
          </div>
        </div>
        <!-- 评论内容展示 -->
        <!-- <div
          class="comment_list"
          v-if="isShowComment"
        >
          <div
            v-for="item in articleCommentInfo"
            :key="item.id"
          >
            <div class="comment_info">
              <div class="avatar_photo_background">
                <img
                  :src="'https://yedeqin-blog-1257421454.cos.ap-nanjing.myqcloud.com/'+item.user_avatar"
                  class="avatar_photo"
                />
              </div>
              <div class="comment_info_left">
                <div>
                  <span class="comment_user_nickname">{{item.user_nickname}}</span>
                  <span>:&nbsp;{{item.comment_content}}</span>
                </div>
                <span class="comment_time">
                  {{item.create_time_toDate}}&nbsp;&nbsp;
                  <span v-if="item.address_city != null">来自{{item.address_city}}</span>
                  <i
                    class="fa fa-commenting-o comment_icon"
                    @click="clickThreadedComment(item.id)"
                  ></i>
                </span>
              </div>
            </div>
            <div
              v-if="threadedCommentId == item.id"
              class="threaded_comment"
            >
              <el-input
                v-model="threaded_comment.comment_content"
                :placeholder="'回复@'+item.user_nickname"
                class="threaded_comment_input"
              />
              <el-button
                v-if="$store.state.isLogin"
                type="primary"
                @click="insertThreadedComment()"
                :loading="threadedCommentButtonLoading"
                class="threaded_comment_button"
              >回复</el-button>
              <el-popover
                placement="top"
                v-else
              >
                <template #reference>
                  <el-button
                    type="primary"
                    class="threaded_comment_button"
                  >回复</el-button>
                </template>
                <template #default>
                  需要登录才能回复哦。^_~
                </template>
              </el-popover>
            </div>
            <div
              v-for="item_child in item.child_article_comment"
              :key="item_child.id"
            >
              <div class="comment_info_child">
                <img
                  :src="'https://yedeqin-blog-1257421454.cos.ap-nanjing.myqcloud.com/'+item_child.user_avatar"
                  class="avatar_photo"
                />
                <div class="comment_info_left">
                  <div>
                    <span class="comment_user_nickname">{{item_child.user_nickname}}</span>
                    <span v-if="item_child.father_user_id != null">:&nbsp;回复<span
                        class="comment_user_nickname">@{{item_child.second_father_user_nickname}}</span></span>
                    <span>:&nbsp;{{item_child.comment_content}}</span>
                  </div>
                  <span class="comment_time">
                    {{item_child.create_time_toDate}}&nbsp;&nbsp;
                    <span v-if="item_child.address_city != null">来自{{item_child.address_city}}
                      <i
                        class="fa fa-commenting-o comment_icon"
                        @click="clickThreadedComment(item_child.id,item.id)"
                      ></i>
                    </span>
                  </span>
                </div>
              </div> -->
        <!-- 回复评论输入框 -->
        <!-- <div
                v-if="threadedCommentId == item_child.id"
                class="threaded_comment"
              >
                <el-input
                  v-model="threaded_comment.comment_content"
                  :placeholder="'回复@'+item_child.user_nickname"
                  class="threaded_comment_input"
                />
                <el-button
                  v-if="$store.state.isLogin"
                  type="primary"
                  @click="insertThreadedComment()"
                  :loading="threadedCommentButtonLoading"
                  class="threaded_comment_button"
                >回复</el-button>
                <el-popover
                  placement="top"
                  v-else
                >
                  <template #reference>
                    <el-button
                      type="primary"
                      class="threaded_comment_button"
                    >回复</el-button>
                  </template>
                  <template #default>
                    需要登录才能回复哦。^_~
                  </template>
                </el-popover>
              </div> -->
        <!-- </div>
          </div>
        </div> -->
        <div
          class="comment_list"
          v-if="isShowComment"
        >
          <div
            v-for="item in articleCommentInfo"
            :key="item.id"
          >
            <MessageShow
              :messageDate="item"
              :style="{'margin_left':'0px', 'margin_top':'24px'}"
              :father_id="item.id"
              scene="first"
              :submitDataFunction="insertThreadedComment"
              @getData="selectArticleComment"
            >
              <!-- <template #avatar>
            <img :src="'https://yedeqin-blog-1257421454.cos.ap-nanjing.myqcloud.com/'+item.user_avatar" />
          </template> -->
            </MessageShow>
            <div
              v-for="item_child in item.child_article_comment"
              :key="item_child.id"
            >
              <MessageShow
                :messageDate="item_child"
                :style="{'margin_left':'50px', 'margin_top':'16px'}"
                :father_id="item.id"
                scene="second"
                :submitDataFunction="insertThreadedComment"
                @getData="selectArticleComment"
              >
              </MessageShow>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import { onMounted, reactive, ref, watch, onUnmounted } from '@vue/runtime-core'
import { useRoute, useRouter } from 'vue-router'
import {
  SELECTARTICLEBYID,
  LIKEARTICLE,
  SELECTARTICLELIKE,
  INSERTARTICLECOMMENT,
  SELECTARTICLECOMMENT
} from '../api/api.js'
import Prism from 'prismjs'
import { ElMessage } from 'element-plus'
import store from '../store/index'
import { setCookie } from '../tools/cookie.ts'
import MessageShow from '@/components/MessageShow.vue'
// import { nextTick } from 'vue'

export default {
  components: {
    MessageShow
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    onMounted(() => {
      if (route.params.id === undefined) {
        router.push({ name: 'ArticleList' })
      } else {
        selectArticleById(route.params.id)
        is_like(route.params.id)
      }
      selectArticleComment()
      handleScroll()
    })
    onUnmounted(() => {
      // 离开页面，关闭滚动监听
      window.removeEventListener('scroll', test)
    })
    // 监听登录状态
    watch(
      () => store.state.isLogin,
      () => {
        is_like(route.params.id)
      }
    )
    // 文章信息展示
    const articleInfo = reactive({
      id: '',
      title: '',
      create_time: '',
      category_name: '',
      tag_name: '',
      content: '',
      cover_photo: ''
    })
    // 文章信息等待状态
    const articleLoading = ref(false)
    // 根据id查询数据
    const selectArticleById = async (id) => {
      articleLoading.value = true
      await SELECTARTICLEBYID({ id: id }).then((response) => {
        const {
          id,
          title,
          create_time,
          category_name,
          tag_name,
          content,
          cover_photo
        } = response.data
        articleInfo.id = article_comment.article_id = article_id.value = id
        articleInfo.title = title
        articleInfo.create_time = create_time
        articleInfo.category_name = category_name
        articleInfo.tag_name = tag_name
        articleInfo.content = content
        articleInfo.cover_photo = cover_photo
        articleLoading.value = false
      })
      await Prism.highlightAll()
      await getCatalogue()
    }
    // 处理图片展示路径
    const photoUrl = (url) => {
      if (url !== '') {
        return (
          'https://yedeqin-blog-1257421454.cos.ap-nanjing.myqcloud.com/' +
          JSON.parse(url)[0]
        )
      }
    }
    // 文章点赞状态
    const articleIsLike = ref(false)
    // 点赞
    const like = () => {
      LIKEARTICLE({ article_id: articleInfo.id }).then((response) => {
        if (response.code === 0) {
          if (response.data) {
            // 点赞成功
            articleIsLike.value = true
            ElMessage({
              showClose: true,
              message: '感谢您的点赞，我会继续努力的！',
              type: 'success'
            })
          } else {
            // 取消点赞成功
            articleIsLike.value = false
            ElMessage({
              showClose: true,
              message: '感谢您曾经的点赞，如遇问题，欢迎留言！',
              type: 'info'
            })
          }
        } else if (response.code === -1) {
          ElMessage({
            showClose: true,
            message: response.msg,
            type: 'warning'
          })
          // 清空cookie
          setCookie('blogName', '', -1)
          setCookie('avatar', '', -1)
          setCookie('is_login', '', -1)
          // 更新vuex的状态
          store.commit('updateIsLogin', false)
          articleIsLike.value = false
        }
      })
    }
    // 获取点赞状态
    const is_like = (article_id) => {
      // 判断是否有登录
      if (store.state.isLogin) {
        // 拿id去查询是否有点赞
        SELECTARTICLELIKE({ article_id: article_id }).then((response) => {
          if (response.code === 0) {
            // 已登录
            articleIsLike.value = response.data
          } else if (response.code === -1) {
            // 未登录
            // 清空cookie
            setCookie('blogName', '', -1)
            setCookie('avatar', '', -1)
            setCookie('is_login', '', -1)
            // 更新vuex的状态
            store.commit('updateIsLogin', false)
            articleIsLike.value = false
          }
        })
      } else {
        // 如果后端没有登录,则清除缓存
        // 清空cookie
        setCookie('blogName', '', -1)
        setCookie('avatar', '', -1)
        setCookie('is_login', '', -1)
        articleIsLike.value = false
      }
    }
    // 提交评论内容
    const article_comment = reactive({
      comment_content: '',
      article_id: '',
      father_id: 0,
      comment_id: 0
    })
    // 文章id
    const article_id = ref()
    // 发表评论的等待按钮
    const articleCommentButtonLoading = ref(false)
    // 发表评论
    const insertArticleComment = () => {
      if (article_comment.comment_content === '') {
        ElMessage({
          showClose: true,
          message: '你忘记填写评论了！',
          type: 'warning'
        })
        return
      }
      articleCommentButtonLoading.value = true
      INSERTARTICLECOMMENT(article_comment).then((response) => {
        if (response.code === 0) {
          selectArticleComment()
          article_comment.comment_content = ''
          articleCommentButtonLoading.value = false
          ElMessage({
            showClose: true,
            message: '评论发表成功！',
            type: 'success'
          })
        } else if (response.code === -1) {
          ElMessage({
            showClose: true,
            message: response.msg,
            type: 'warning'
          })
          // 清空cookie
          setCookie('blogName', '', -1)
          setCookie('avatar', '', -1)
          setCookie('is_login', '', -1)
          // 更新vuex的状态
          store.commit('updateIsLogin', false)
          articleCommentButtonLoading.value = false
        }
      })
    }

    // 评论数据
    const articleCommentInfo = ref()
    // 评论内容展示状态
    const isShowComment = ref(false)
    // 获取评论
    const selectArticleComment = () => {
      SELECTARTICLECOMMENT({ article_id: route.params.id, father_id: 0 }).then(
        (response) => {
          if (response.code === 0) {
            articleCommentInfo.value = response.data
            if (articleCommentInfo.value.length === 0) {
              isShowComment.value = false
            } else {
              isShowComment.value = true
            }
          }
        }
      )
    }
    // 回复评论输入框展示id
    const threadedCommentId = ref()
    // 回复评论内容
    const threaded_comment = reactive({
      comment_content: '',
      article_id: '',
      father_id: 0,
      comment_id: 0
    })
    // 点击回复评论icon
    const clickThreadedComment = (activate_id, father_id) => {
      threaded_comment.comment_content = ''
      if (father_id === undefined) {
        threaded_comment.father_id = activate_id
      } else {
        threaded_comment.father_id = father_id
        threaded_comment.comment_id = activate_id
      }

      if (threadedCommentId.value === activate_id) {
        threadedCommentId.value = 0
        threaded_comment.comment_id = 0
      } else {
        threadedCommentId.value = activate_id
      }
    }
    // 回复评论按钮等待状态
    const threadedCommentButtonLoading = ref(false)
    // 提交回复评论
    const insertThreadedComment = (father_id, comment_id, message_contents) => {
      return new Promise((resolve, reject) => {
        INSERTARTICLECOMMENT({
          father_id: father_id,
          comment_id: comment_id,
          comment_content: message_contents,
          article_id: article_id.value
        })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
      // if (threaded_comment.comment_content === '') {
      //   ElMessage({
      //     showClose: true,
      //     message: '你忘记填写回复了！',
      //     type: 'warning'
      //   })
      //   return
      // }
      // threadedCommentButtonLoading.value = true
      // INSERTARTICLECOMMENT(threaded_comment).then((response) => {
      //   if (response.code === 0) {
      //     selectArticleComment()
      //     threaded_comment.comment_content = ''
      //     threaded_comment.comment_id = 0
      //     threadedCommentId.value = 0
      //     threadedCommentButtonLoading.value = false
      //     ElMessage({
      //       showClose: true,
      //       message: '回复发表成功！',
      //       type: 'success'
      //     })
      //   } else if (response.code === -1) {
      //     ElMessage({
      //       showClose: true,
      //       message: response.msg,
      //       type: 'warning'
      //     })
      //     // 清空cookie
      //     setCookie('blogName', '', -1)
      //     setCookie('avatar', '', -1)
      //     setCookie('is_login', '', -1)
      //     // 更新vuex的状态
      //     store.commit('updateIsLogin', false)
      //     threadedCommentButtonLoading.value = false
      //   }
      // })
    }

    // 回复评论按钮等待状态
    const catalogueInfo = ref()
    // 自动获取目录信息
    const getCatalogue = () => {
      const parent = document.getElementById('content')
      const doms = parent.querySelectorAll('h1,h2,h3,h4,h5,h6')
      const hEles = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6']
      const catalogue = []
      let index = 0
      if (doms.length > 0) {
        doms.forEach((element) => {
          var nodetext = element.innerHTML.replace(/<\/?[^>]+>/g, '')
          nodetext = nodetext.replace(/&nbsp;/gi, '')
          const name = element.nodeName.toLowerCase()
          if (hEles.includes(name)) {
            index++
            const id = `catalogue_${index}`
            element.setAttribute('id', id)
            const level = name.replace('h', '')
            catalogue.push({
              id: id,
              key: name,
              title: nodetext,
              level: Number(level)
            })
          }
        })
        catalogueInfo.value = catalogue
      }
    }
    // 当前（历史）激活菜单id
    const historyActivate = reactive({ id: -1 })
    // 阅读进度
    const progressPercentage = ref()
    // 滚动的监听方法
    function handleScroll() {
      window.addEventListener('scroll', test)
    }
    const test = () => {
      // console.log(window.scrollY)
      // scrollTop.value = window.scrollY
      // 当前滚动的距离
      var current = document.documentElement.scrollTop
      // 页面的总高度
      var offsetHeight = document.documentElement.offsetHeight
      // 页面的可视高度
      var clientHeight = document.documentElement.clientHeight
      // console.log(
      //   (current / (offsetHeight - clientHeight - 500)).toFixed(2) * 100
      // )
      progressPercentage.value =
        (current / (offsetHeight - clientHeight - 500)).toFixed(2) * 100
      if (current < 300) {
        // 当在头部时，激活第一个
        if (catalogueInfo.value) {
          activateCatalogueClass(catalogueInfo.value[0].id)
        }
      } else if (current === offsetHeight - clientHeight) {
        // 当在最尾部，激活最后一个
        activateCatalogueClass(
          catalogueInfo.value[catalogueInfo.value.length - 1].id
        )
      } else {
        let activateCatalogue = ''
        if (catalogueInfo.value) {
          catalogueInfo.value.forEach((catalogue) => {
            if (
              current >=
              document.getElementById(catalogue.id).offsetTop - 25
            ) {
              activateCatalogue = catalogue
            }
          })
        }

        if (activateCatalogue) {
          // 激活菜单
          activateCatalogueClass(activateCatalogue.id)
        }
      }
    }
    // 首次进入判断滚动高度
    // const firstShow = () => {
    //   // 当前滚动的距离
    //   var current = document.documentElement.scrollTop
    //   if (current < 300) {
    //     // 当在头部时，激活第一个
    //     if (catalogueInfo.value) {
    //       activateCatalogueClass(catalogueInfo.value[0].id)
    //     }
    //   }
    // }
    // 激活目录
    const activateCatalogueClass = (id) => {
      if (id !== historyActivate.id) {
        // 取消激活菜单
        const historyCatalogue = document.getElementById(
          'catalogue_' + historyActivate.id
        )
        if (historyCatalogue) {
          historyCatalogue.classList.remove('catalogue_item_activate')
        }
        // 激活菜单
        const catalogue = document.getElementById('catalogue_' + id)
        if (catalogue) {
          catalogue.classList.add('catalogue_item_activate')
          historyActivate.id = id
        }
      }
    }

    return {
      articleInfo,
      photoUrl,
      articleLoading,
      like,
      articleIsLike,
      article_comment,
      articleCommentButtonLoading,
      insertArticleComment,
      articleCommentInfo,
      clickThreadedComment,
      threadedCommentId,
      threaded_comment,
      threadedCommentButtonLoading,
      insertThreadedComment,
      isShowComment,
      selectArticleComment,
      catalogueInfo,
      progressPercentage
    }
  }
}
</script>
<style>
.content p {
  line-height: 25px;
  /* text-indent: 2em; */
  padding-bottom: 8px;
}

.content p img {
  display: block;
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}
h2 {
  font-size: 1.5em;
  margin: 0.75em 0;
}
h3 {
  font-size: 1.17em;
  margin: 0.83em 0;
}
h5 {
  font-size: 0.83em;
  margin: 1.5em 0;
}
h6 {
  font-size: 0.75em;
  margin: 1.67em 0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bolder;
}
</style>
<style src="../css/article.css" scoped>
</style>
